import {ApiEventDispatcher} from "../events/ApiEventDispatcher";
import {GoogleAnalytics} from "../google/GoogleAnalytics";
import {SiteCookieAlert} from "../../site/SiteCookieAlert";
import {SiteApiConfig} from "../../site/SiteApiConfig";
import {ApiConfig} from "../config/ApiConfig";
import {GoogleFireBase} from "../google/GoogleFireBase";
import {FacebookSDK} from "../facebook/FacebookSDK";
import {SiteApiConnector} from "../../site/SiteApiConnector";
import {FacebookWebSDK} from "../facebook/FacebookWebSDK";
import {FacebookCordovaSDK} from "../facebook/FacebookCordovaSDK";
import {ApiConnector} from "../connector/ApiConnector";
import {CookieConfirmForm} from "../ui/CookieConfirmForm";
import {GoogleDfp} from "../google/GoogleDfp";


export class Api extends ApiEventDispatcher
{
    protected _window:any;

    protected _analytics:GoogleAnalytics;
    protected _cookie_alert_model:CookieConfirmForm;
    protected _facebook:FacebookSDK;
    protected _firebase:GoogleFireBase;
    protected _connector:ApiConnector;
    protected _dfp:GoogleDfp;

    protected init():void
    {
        let w:any = window;
        if (w.cordova)
        {
            if (!this._facebook) this._facebook = new FacebookCordovaSDK(this);
        }
        else
        {
            if (!this._facebook) this._facebook = new FacebookWebSDK(this);
        }

        if (!this._firebase)              this._firebase                = new GoogleFireBase(this);
        if (!this._analytics)             this._analytics               = new GoogleAnalytics(this);
        if (!this._connector)             this._connector               = new ApiConnector(this);
        if (!this._cookie_alert_model)    this._cookie_alert_model      = new CookieConfirmForm(this);
        if (!this._cookie_alert_model)    this._cookie_alert_model      = new CookieConfirmForm(this);
        if (!this._dfp)                   this._dfp                     = new GoogleDfp(this);
    }
    public connector():ApiConnector
    {
        return this._connector;
    }
    public facebook_sdk():FacebookSDK
    {
        return this._facebook;
    }
    public google_firebase():GoogleFireBase
    {
        return this._firebase;
    }

    public get config():ApiConfig
    {
        return this._config
    }
    constructor(protected _config:ApiConfig = null)
    {
        super();

        try { console.clear();}
        catch(e){}

        this._window = window;

        if ((document.readyState === "interactive") || (document.readyState === "complete"))
        {
            this.init();
        }
        else
        {
            document.addEventListener("DOMContentLoaded", (_event:any):void =>
            {
                this.init();
            });
        }
    }
}