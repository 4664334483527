import {SiteApi} from "../../site/SiteApi";
import {SiteApiConfig} from "../../site/SiteApiConfig";
import {ApiScriptLoader} from "../loaders/ApiScriptLoader";
import {ApiEventDispatcher} from "../events/ApiEventDispatcher";
import {Api} from "../api/Api";

export class GoogleAnalytics  extends ApiEventDispatcher
{
    private js =
    [
        "https://www.google-analytics.com/analytics.js",
    ];
    public event():void
    {
        //https://developers.google.com/analytics/devguides/collection/gtagjs/events
    }
    private init():void
    {
        let id:string = this._api.config.get(SiteApiConfig.APP_GOOGLE_ANALYTICS_ID);
        if (id)
        {
            let w:any = window;

            ApiScriptLoader.getInstance().getScripts(this.js, true, ():void =>
            {
                w.ga('create',id, 'auto');
                w.ga('send', 'pageview');
            });
        }
    }
    constructor(private _api:Api)
    {
        super();
        this.init();
    }
}