import {SiteApiConfig} from "../../site/SiteApiConfig";
import {ApiScriptLoader} from "../loaders/ApiScriptLoader";
import {SiteApi} from "../../site/SiteApi";
import {ApiCookies} from "../cookies/ApiCookies";
import {ApiEventDispatcher} from "../events/ApiEventDispatcher";
import {Api} from "../api/Api";

export enum FacebookStatus
{
    connected       = "connected",
    not_authorized  = "not_authorized",
    unknown         = "unknown"
}
export interface FacebookAuthResponse
{
    accessToken:string,
    expiresIn:string,
    signedRequest:string,
    userID:string,
    name:string,
    email:string,
    avatar:string
}

export interface FacebookLoginResponse
{
    status: FacebookStatus,
    authResponse: FacebookAuthResponse
}
export interface FacebookUser
{
    user_name:string,
    user_id:string,
    user_avatar:string
    user_email:string,
    user_country_code:string

}

interface IFacebookSDK
{
    getAvatarUrl():string;
    invite(_message:string):void;
    login():void
    logout():void
    isLogined():boolean
}

export class FacebookSDK extends ApiEventDispatcher implements IFacebookSDK
{
    protected _FB:any;

    protected _logined:boolean = false;
    protected _initialized:boolean = false;

    protected _fb_user:FacebookUser = {} as FacebookUser;


    public static FACEBOOK_LOGIN_INIT:string            = "facebook_login_init";
    public static FACEBOOK_LOGIN_ERROR:string           = "facebook_login_error";
    public static FACEBOOK_LOGIN_CHANGE_STATUS:string   = "facebook_change_status";
    public static FACEBOOK_LOGIN_ON_LOGIN:string        = "facebook_change_on_login";
    public static FACEBOOK_LOGIN_ON_LOGOUT:string       = "facebook_change_on_logout";

    protected LOGINED:string = "logined";


    public get user():FacebookUser
    {
        return this._fb_user;
    }
    public get initialized():boolean
    {
        return this._initialized;
    }
    public initLoginedUser(_user:any = {}):void
    {
        //{"user_name":"Denis Sokoloff","user_id":null,"user_fb_id":"10212882354158968","user_country_code":"UA","user_subscribe_end_data":null,"premium":false}
        this._fb_user.user_name         = _user["user_name"];
        this._fb_user.user_avatar       = this.getAvatarUrlByID(_user["user_fb_id"]);
        this._fb_user.user_id           = _user["user_id"];
        this._fb_user.user_country_code = _user["user_country_code"];
    }

    public getAvatarUrlByID(_id:string):string
    {
        return null;
    }
    public getAvatarUrl():string
    {
        return null;
    }
    public get response():FacebookLoginResponse
    {
        return null
    }
    public invite(_message:string = "Invite Friends"):void
    {

    }
    public login():void
    {
    }
    public logout():void
    {
    }
    public init():void
    {

    }
    public isLogined():boolean
    {
        return false
    }
    constructor(protected _api:Api)
    {
        super();
    }
}