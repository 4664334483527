export class ApiConfig
{
    public static APP_SITE_URL:string                   = "app_site_url";
    public static APP_COOKIES_CONFIG:string             = "app_cookies_config";

    public static APP_GOOGLE_ANALYTICS_ID:string        = "app_google_analytics_id";
    public static APP_GOOGLE_CAPTCHA_KEY:string         = "app_google_captcha_key";
    public static APP_GOOGLE_DFP_ENABLE:string          = "app_google_dfp_enable";

    public static APP_GOOGLE_FIREBASE_CONFIG:string     = "app_google_firebase_config";
    public static APP_GOOGLE_FIREBASE_KEY_PAIR:string   = "app_google_firebase_key_pair";


    private _config:any = {};

    public set(_name:string, _value:any):void
    {
        this._config[_name] = _value;
    }
    public get(_name:string):any
    {
        return this._config[_name];
    }
    public merge(_c:any):void
    {
        for (let name in _c)
            if ((this._config[name] == null) || (this._config[name] == undefined))
                this._config[name] = _c[name];
    }
    constructor()
    {
        this._config = {};
    }
}