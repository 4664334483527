export class ApiRequest
{
    public request(_url:string, _type:string = "GET", _vars:any[] = [], _handler:(_params:string)=>void = null):XMLHttpRequest
    {
        _url  = (_url == undefined)  ? ""       : _url;
        _type = (_type == undefined) ? "GET"    : _type;
        _vars = (_vars == undefined) ? []       : _vars;

        let vars = "";
        for (let key in _vars) vars += key+"="+encodeURIComponent(_vars[key])+"&";

        let http_request:XMLHttpRequest = new XMLHttpRequest();

        http_request.onreadystatechange = ():void =>
        {
            let params:string;
            if ((http_request.readyState === XMLHttpRequest.DONE) && (http_request.status === 200))
            {
                //http_request.abort();
                params = http_request.responseText;
                if (_handler) _handler(params);
            }
        };

        http_request.open(_type, _url, true);
        http_request.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
        http_request.send(vars);

        return http_request

    }
    public requestJson(_url:string, _type:string = "GET", _vars:any[] = [], _handler:(_params:any)=>void = null):XMLHttpRequest
    {
        let http_request:XMLHttpRequest = this.request(_url, _type, _vars, (_param:string):void=>
        {
            let params:any = {};
            if (_param)
            {
                try
                {
                    params = JSON.parse(_param);
                }
                catch(e)
                {
                    console.warn(e)
                }
            }
            if (_handler) _handler(params);
        });
        return http_request
    }
    private static _instance: ApiRequest = new ApiRequest();
    public static getInstance(): ApiRequest
    {
        return ApiRequest._instance;
    };
    constructor()
    {
        if(ApiRequest._instance)
        {
            throw new Error("Error: Instantiation failed: Use SingletonClass.getInstance() instead of new.");
        }
        ApiRequest._instance = this;
    }
}
