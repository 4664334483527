import {CathTubeSiteApiConfig} from "./CatchTubeSiteApiConfig";
import {Api} from "../../../../../../onlinemahjong.games/src/com/boombing/libs/api/Api";
import {ApiConfig} from "../../../../../../onlinemahjong.games/src/com/boombing/libs/config/ApiConfig";

export class CatchTubeSiteApi extends Api
{
    constructor(protected _config:ApiConfig = null)
    {
        super(_config);
    }
}
(function(_window:any)
{
    _window.SiteApi         = CatchTubeSiteApi;
    _window.SiteApiConfig   = CathTubeSiteApiConfig;

})(window);
