import {ApiCookies} from "../cookies/ApiCookies";
import {ApiConfig} from "../config/ApiConfig";
import {Api} from "../api/Api";

export interface CookiesAlertConfig
{
    message:string,
    dismiss:string,
    link:string,
    href:string,
}
export class CookieConfirmForm
{
    private OK:string = "ok";

    private COOKIE_ALERT:string = "cookie_alert";
    private COOKIE_BTN:string   = "cookie-btn";

    protected _config:CookiesAlertConfig;
    protected _container:HTMLDivElement;

    private insertAfter(_target:HTMLElement,_element:HTMLElement)
    {
        _target.parentNode.insertBefore(_element, _target.nextSibling);
    }
    protected initEvents():void
    {
        let btn:HTMLElement = document.getElementById(this.COOKIE_BTN);
        btn.addEventListener("click", (_event:Event):void =>
        {
            ApiCookies.getInstance().setCookies(this.COOKIE_ALERT, this.OK, 2 * 365, "/");

            _event.preventDefault();
            _event.stopPropagation();

            this._container.classList.remove("open");

            setTimeout(():void =>
            {
                this._container.remove();
            }, 1500);
        });

        setTimeout(():void =>
        {
            this._container.classList.add("open");
        }, 1500);
    }

    protected render():void
    {
        let st:string = this._render_code_tamplate;

        st = st.replace("{message}",    this._config.message);
        st = st.replace("{href}",       this._config.href);
        st = st.replace("{link}",       this._config.link);

        this._container = document.createElement("div");
        this._container.classList.add("cookie-alert");
        this._container.innerHTML = st;

        document.body.appendChild(this._container);
        this.initEvents();
    }
    private init():void
    {
        this._config = this._api.config.get(ApiConfig.APP_COOKIES_CONFIG);
        if (this._config)
        {
            if (ApiCookies.getInstance().getCookie(this.COOKIE_ALERT) != this.OK) this.render();
        }
    }
    constructor(protected _api:Api, protected _render_code_tamplate:string = '<!--googleoff: index--><div><div class="cookie-alert-c1"><p>{message} <a href="{href}">{link}</a></p></div> <div class="cookie-alert-c2"><a class="button" id="cookie-btn">Ok</a></div></div> <!--googleon: index-->')
    {
        this.init();
    }
}
