export class ApiEventDispatcher
{
    private _handlers:any = {};
    public addEventListener = (_event_name:string, _handler:(...params:any[])=>void):void =>
    {
        if (this._handlers[_event_name] == null)
        {
            this._handlers[_event_name] = [];
        }
        this._handlers[_event_name].push(_handler);
    };
    public removeEventListener = (_event_name:string, _handler:(...params:any[])=>void):void =>
    {
        let handlers = this._handlers[_event_name];
        if (handlers)
        {
            let n:number = handlers.length;
            for (let i=0; i<n; i++)
            {
                let handler:any = handlers[i];
                if (handler == _handler)
                {
                    handlers.splice(i, 1);
                    break;
                }
            }
        }
    };
    public dispatch = (_event_name:string, ...params:any[]):void =>
    {
        if (this._handlers[_event_name])
        {
            for (let _handler of this._handlers[_event_name])
            {
                try
                {
                    _handler.apply(null, params);
                }
                catch(e){}
            }
        }
    };
    constructor()
    {

    }
}