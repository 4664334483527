import {ApiConfig} from "../config/ApiConfig";
import {ApiRequest} from "../loaders/ApiRequest";
import {Api} from "../api/Api";

export class ApiConnector
{
    protected getRoot():string
    {
        let foo:string = this._api.config.get(ApiConfig.APP_SITE_URL);

        let root:string = foo ? foo : "";
        return root
    }
    public login(_user_fb_id:string, _access_token:string, _handler:(_result:any)=>void):void
    {
        let w:any = window;
        let url:string = this.getRoot()+"/api/login";
        let vars:any =
            {
                "token":        _access_token,
                "user_fb_id":   _user_fb_id,
                "language": (w.language) ? w.language : "en"
            };

        ApiRequest.getInstance().requestJson(url, "POST", vars, (_result:any):void =>
        {
            if (_handler) _handler(_result)
        });
    }
    public logout(_handler:(_result:any)=>void):void
    {
        let w:any = window;
        let url:string = this.getRoot()+"/api/logout";
        let vars:any = {};

        ApiRequest.getInstance().requestJson(url, "POST", vars, (_result:any):void =>
        {
            if (_handler) _handler(_result)
        });
    }
    public removeNotificationToken(_token_id:string, _handler:(_result:any)=>void):void
    {
        let w:any = window;
        let url:string = this.getRoot()+"/api/remove-notification-token";
        let vars:any =
            {
                "token_id":  _token_id,
                "language": (w.language) ? w.language : "en"
            };

        ApiRequest.getInstance().requestJson(url, "POST", vars, (_result:any):void =>
        {
            if (_handler) _handler(_result)
        });
    }
    public addNotificationToken(_token:string, _handler:(_result:any)=>void):void
    {
        let w:any = window;
        let url:string = this.getRoot()+"/api/add-notification-token";
        let vars:any =
            {
                "token": _token,
                "language": (w.language) ? w.language : "en"
            };
        ApiRequest.getInstance().requestJson(url, "POST", vars, (_result:any):void =>
        {
            if (_handler) _handler(_result)
        });
    }
    public addRemindNotification(_token:string, _handler:(_result:any)=>void):void
    {
        let w:any = window;
        let url:string = this.getRoot()+"/api/add-remind-notification";
        let vars:any =
            {
                "token": _token,
                "language": (w.language) ? w.language : "en"
            };
        ApiRequest.getInstance().requestJson(url, "POST", vars, (_result:any):void =>
        {
            if (_handler) _handler(_result)
        });
    }
    constructor(protected _api:Api)
    {
    }
}