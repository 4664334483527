import {SiteApiConfig} from "../../site/SiteApiConfig";
import {ApiCookies} from "../cookies/ApiCookies";
import {FacebookLoginResponse, FacebookSDK, FacebookStatus, FacebookUser} from "./FacebookSDK";
import {Api} from "../api/Api";
import {FacebookWebSDK} from "./FacebookWebSDK";

export class FacebookCordovaSDK extends FacebookSDK
{
    private _last_response:FacebookLoginResponse;

    public getAvatarUrl():string
    {
        let image_url:string;
        if ((this._last_response) && (this._last_response.authResponse) && (this._last_response.authResponse.userID))
        {
            image_url = "https://graph.facebook.com/"+this._last_response.authResponse.userID+"/picture?type=large";
        }
        return image_url
    }
    public get response():FacebookLoginResponse
    {
        return this._last_response;
    }
    public invite(_message:string = "Invite Friends"):void
    {
        if (this._FB)
        {
            let request:any =
                {
                    method: "apprequests",
                    message: _message,
                    //data: new Date(),
                    title: _message,
                    actionType: 'askfor',
                    filters: 'app_non_users'
                };

            this._FB.showDialog(request,
                ():void =>
                {
                    console.log("invite ok")
                },
                ():void =>
                {
                    console.log("invite error")
                });
        }
    }
    public login():void
    {
        if (this._FB)
        {
            let app_scope:string = this._api.config.get(SiteApiConfig.APP_FACEBOOK_LOGIN_SCOPES);
            if (!app_scope) app_scope = 'public_profile';
            let permissions:string[] = app_scope.split(",");


            this._FB.login(permissions,
                (_response:FacebookLoginResponse):void =>
                {
                    console.log(_response);
                    this.changeStatus(_response);
                },
                (_error:string):void =>
                {
                    console.log(_error);
                }
            );
        }
        else
        {
            this.changeStatus(null);
        }
    }
    public logout():void
    {
        if (this._FB)
        {
            this._FB.logout((_response:FacebookLoginResponse):void =>
            {
                console.log(_response);
                this.changeStatus(_response);
            });
        }
        else
        {
            this.changeStatus(null);
        }
    }
    private getUserName(_handler:(_name:string)=>void = null):void
    {

        if (this._FB)
        {
            //this._FB.api('/me', {fields: 'name'}, (_response:any):void =>
            this._FB.api('/me?fields=name', ["public_profile"], (_response:any):void =>
            {
                console.log(_response.name);

                let name:string;

                if (_response)
                {
                    name  = _response.name;
                }

                if (_handler) _handler(name);
            });
        }
    }
    private getLocation(_handler:(_location:any)=>void = null):void
    {

        if (this._FB)
        {
            //this._FB.api('/me', {fields: 'name'}, (_response:any):void =>
            this._FB.api('/me?fields=location{location{country,country_code}}', ["public_profile"], (_response:any):void =>
            {
                console.log(_response.location);

                let location:any;

                if (_response)
                {
                    location  = _response.location;
                }

                if (_handler) _handler(location);
            });
        }
    }
    public isLogined():boolean
    {
        return this._logined;
    }
    private loginAction(_response:FacebookLoginResponse):void
    {
        this._api.connector().login(_response.authResponse.userID, _response.authResponse.accessToken, (_result:any):void =>
        {
            console.log(_result);
            if (_result["error"] == -1)
            {
                this.getUserName((_name:string):void=>
                {
                    this._last_response.authResponse.name   = _name;
                    this._last_response.authResponse.avatar = this.getAvatarUrl();

                    this._fb_user.user_name             = this._last_response.authResponse.name;
                    this._fb_user.user_avatar           = this._last_response.authResponse.avatar;
                    this._fb_user.user_id               = this._last_response.authResponse.userID;
                    this._fb_user.user_country_code     = _result["country_code"];

                    this._logined = true;
                    ApiCookies.getInstance().setCookies(this.LOGINED, 1, 365, "/");

                    this.dispatch(FacebookSDK.FACEBOOK_LOGIN_ON_LOGIN, _response);

                    /*
                    this.getLocation((_location:any):void=>
                    {
                        if ((_location) && (_location.location))
                        {
                            this._fb_user.user_country_code = _location.location.country_code;
                        }
                        else
                        {
                            this._fb_user.user_country_code = "un";
                        }
                        this.dispatch(FacebookSDK.FACEBOOK_LOGIN_ON_LOGIN, _response);
                    });
                    */
                });
            }
            else
            {
                this.logout();
            }
        })
    }
    private logoutAction(_response:FacebookLoginResponse):void
    {
        if (this.isLogined())
        {
            this._api.connector().logout((_result:any):void =>
            {
                this._logined = false;
                ApiCookies.getInstance().setCookies(this.LOGINED, 0, 365, "/");
                this._fb_user = {} as FacebookUser
                this.dispatch(FacebookSDK.FACEBOOK_LOGIN_ON_LOGOUT, _response);
            })
        }
        else
        {
            this.dispatch(FacebookSDK.FACEBOOK_LOGIN_ON_LOGOUT, _response);
        }
    }

    private changeStatus(_response:FacebookLoginResponse):void
    {
        this.dispatch(FacebookSDK.FACEBOOK_LOGIN_CHANGE_STATUS, _response);
        this._last_response = _response;

        if (_response)
        {
            switch (_response.status)
            {
                case FacebookStatus.connected:
                    this.loginAction(_response);
                    break;

                default:
                    this.logoutAction(_response);
                    break;
            }
        }
    };
    public init():void
    {
        this.initCordovaFB();
    }
    private initCordovaFB = ():void =>
    {

        let app_id:string        = this._api.config.get(SiteApiConfig.APP_FACEBOOK_LOGIN_APP_ID);
        let auto_login:boolean   = this._api.config.get(SiteApiConfig.APP_FACEBOOK_AUTO_LOGIN);

        let p:string = this._api.config.get(SiteApiConfig.APP_FACEBOOK_LOGIN_SCOPES);
        let permissions:string[] = p.split(",");


        console.log("FB Cordova INIT", app_id);

        if (app_id)
        {
            let w:any = window;
            console.log("FB Cordova facebookConnectPlugin", w, w.facebookConnectPlugin);

            if (w.facebookConnectPlugin)
            {
                this._FB = w.facebookConnectPlugin;
                this._FB.getLoginStatus(
                    (_response:FacebookLoginResponse):void =>
                    {
                        let logined:number = Number(ApiCookies.getInstance().getCookie(this.LOGINED));
                        this.changeStatus(_response);

                        if ((_response.status != FacebookStatus.connected) && ((auto_login) || (logined == 1)))
                        {
                            this.login();
                        }
                    },
                    (_error:string):void =>
                    {
                        console.log(_error);
                    });
            }
        }
        else
        {
            this.dispatch(FacebookSDK.FACEBOOK_LOGIN_ERROR);
        }
    }
    constructor(_api:Api)
    {
        super(_api);
        document.addEventListener("deviceready", this.initCordovaFB, false);
    }
}