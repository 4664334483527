export class ApiCookies
{
    public setCookies = (_name:string, _value:any, _expires_days:number = null, _path:string = null, _domain:string = null, _secure:boolean = null):void =>
    {
        let date:Date;
        //exdate.setDate(exdate.getDate() + exdays);
        if (_expires_days)
        {
            date = new Date();
            date.setDate(date.getDate() + _expires_days);
        }

        if (_domain == null)
        {
            let host:string = location.hostname;
            _domain = "."+(location.hostname);
        }
        if (_secure == null)
        {
            _secure = (location.protocol == "https:") ? true : false;
        }
        let value:string = "_"+_name + "=" + encodeURIComponent(_value) +
        ((date)          ?   "; expires="    + date.toUTCString()  : "") +
        ((_path)         ?   "; path="       + _path     : "") +
        ((_domain)       ?   "; domain="     + _domain   : "") +
        ((_secure)       ?   "; secure"                  : "");

        //console.log(value);
        document.cookie = value;
    };

    public getCookie = (_name:string):any =>
    {
        _name = "_"+_name;
        let value:any;


        let cookie:string = " " + document.cookie;
        let search:string = " " + _name + "=";
        let offset:number = 0;
        let end:number    = 0;

        offset = cookie.indexOf(search);
        if (offset != -1)
        {
            offset += search.length;
            end = cookie.indexOf(";", offset);
            if (end == -1) end = cookie.length;
            value = decodeURIComponent(cookie.substring(offset, end));
        }
        //console.log("get:", _name,document.cookie, value);

        return(value);
    };
    public delCookie = (_name:string):void =>
    {
        document.cookie = _name + "=" + "; expires=Thu, 01 Jan 1970 00:00:01 GMT";
    };

    private static _instance: ApiCookies = new ApiCookies();
    public static getInstance(): ApiCookies
    {
        return ApiCookies._instance;
    };
    constructor()
    {
        if(ApiCookies._instance)
        {
            throw new Error("Error: Instantiation failed: Use SingletonClass.getInstance() instead of new.");
        }
        ApiCookies._instance = this;
    }
}