import {ApiScriptLoader} from "../loaders/ApiScriptLoader";
import {SiteApiConfig} from "../../site/SiteApiConfig";
import {ApiCookies} from "../cookies/ApiCookies";
import {ApiEventDispatcher} from "../events/ApiEventDispatcher";
import {Api} from "../api/Api";


export interface GoogleFirebaseConfigStructure
{
    apiKey:string,
    authDomain:string,
    databaseURL:string,
    projectId:string,
    storageBucket:string,
    messagingSenderId:string
}

export class GoogleFireBase  extends ApiEventDispatcher
{

    private COOKIES_NOTIFICATIONS_ID_KEY:string = "notification_id";
    private COOKIES_NOTIFICATIONS_TOKEN:string  = "notification_token";


    private _FIREBASE:any;
    private _MESSAGING:any;

    private js =
    [
        "https://www.gstatic.com/firebasejs/4.13.0/firebase.js",
        "https://www.gstatic.com/firebasejs/4.13.0/firebase-app.js",
        "https://www.gstatic.com/firebasejs/4.13.0/firebase-messaging.js"
    ];

    private getToken(_handler:(_token:string)=>void)
    {
        let token:string = null;
        this._MESSAGING.getToken().then((_token:string):void =>
        {
            console.log("getToken ", _token);
            if (_token)
            {
                token = _token;
            }
            _handler (token);

        }).catch(function(_err:any)
        {
            console.log('An error occurred while retrieving token. ', _err);
            _handler (token);
        });
    }
    private removeToken(_handler:()=>void = null):void
    {
        let _token_id:string = ApiCookies.getInstance().getCookie(this.COOKIES_NOTIFICATIONS_TOKEN);
        console.log('Remove client with token_id:',_token_id);

        if ((Number(_token_id)) && (Number(_token_id) != -1))
        {
            this._api.connector().removeNotificationToken(_token_id, (_result:any):void =>
            {
                ApiCookies.getInstance().setCookies(this.COOKIES_NOTIFICATIONS_ID_KEY, -1);
                ApiCookies.getInstance().setCookies(this.COOKIES_NOTIFICATIONS_TOKEN,  -1);

                if (_handler) _handler();
            });
        }
        else
        {
            if (_handler) _handler();
        }
    }
    private addToken(_token:string):void
    {
        let saved_token:string = ApiCookies.getInstance().getCookie(this.COOKIES_NOTIFICATIONS_TOKEN);

        console.log('Add or Update client with token_id:',_token);

        if ((_token) && (saved_token != _token))
        {
            this.removeToken(():void =>
            {
                this._api.connector().addNotificationToken(_token, (_result:any):void =>
                {
                    if (_result)
                    {
                        let token_id:number = Number(_result["id"]);
                        console.log("Save token_id to cookies", token_id);

                        ApiCookies.getInstance().setCookies(this.COOKIES_NOTIFICATIONS_ID_KEY, token_id);
                        ApiCookies.getInstance().setCookies(this.COOKIES_NOTIFICATIONS_TOKEN, _token);
                    }
                });
            });
        }
        else if ((_token) && (saved_token == _token))
        {
            this._api.connector().addRemindNotification(_token, (_result:any):void =>
            {
                console.log("addRemindNotification",_result);
            });
        }
    }

    private initMessagingEvents():void
    {
        this._MESSAGING.onTokenRefresh(():void =>
        {
            this._MESSAGING.getToken().then((_refreshed_token:any):void =>
            {
                console.log('Token refreshed.',_refreshed_token);
                this.addToken(_refreshed_token);

            }).catch((_err:any):void =>
            {
                console.log('Unable to retrieve refreshed token ', _err);
            });
        });

        this._MESSAGING.onMessage((_payload:any):void =>
        {
            console.log('Message received. ', _payload);
            let notification:any = Notification;
            if ((notification) && (notification.permission))
            {
                let notification:Notification = new Notification(_payload.notification.title,
                    {
                        icon: _payload.notification.icon,
                        body: _payload.notification.body,
                    });
                notification.onclick = ():void =>
                {
                    window.open(_payload.notification.click_action);
                };
            }
        });
    }

    private initMessaging(_config:GoogleFirebaseConfigStructure, _key:string):void
    {
        this._MESSAGING = this._FIREBASE.messaging();
        this.initMessagingEvents();

        if ((navigator.serviceWorker) && (navigator.serviceWorker.register))
        {
            navigator.serviceWorker.register('/js/workers/firebase-messaging-sw-'+String(_config.messagingSenderId)+'.js').then((_registration:ServiceWorkerRegistration) =>
            {
                this._MESSAGING.useServiceWorker(_registration);
            });
        }
        this._MESSAGING.usePublicVapidKey(_key);
        this.getToken((_token:string):void =>
        {
            if (_token == null)
            {
                this._MESSAGING.requestPermission().then(():void =>
                {
                    console.log('Notification permission granted.');
                    this.getToken((_token:string):void =>
                    {
                        this.addToken(_token);
                    });

                }).catch((_err:any):void =>
                {
                    this.removeToken();
                });
            }
            else
            {
                this.addToken(_token);
            }
        });
    }
    private initFireBase(_config:GoogleFirebaseConfigStructure, _key:string):void
    {
        let w:any = window;
        if (w.firebase)
        {
            this._FIREBASE = w.firebase;
            this._FIREBASE.initializeApp(_config);

            this.initMessaging(_config, _key)
        }
    }
    private init():void
    {
        let config:GoogleFirebaseConfigStructure = this._api.config.get(SiteApiConfig.APP_GOOGLE_FIREBASE_CONFIG);
        let key:string = this._api.config.get(SiteApiConfig.APP_GOOGLE_FIREBASE_KEY_PAIR);

        let id_key:string = ApiCookies.getInstance().getCookie(this.COOKIES_NOTIFICATIONS_ID_KEY);
        if (id_key == null) ApiCookies.getInstance().setCookies(this.COOKIES_NOTIFICATIONS_ID_KEY, -1);

        let token:string = ApiCookies.getInstance().getCookie(this.COOKIES_NOTIFICATIONS_TOKEN);
        if (token == null) ApiCookies.getInstance().setCookies(this.COOKIES_NOTIFICATIONS_TOKEN, -1);

        if ((config) && (key))
        {
            ApiScriptLoader.getInstance().getScripts(this.js, true, ():void =>
            {
                console.log("Firebase Messaging loaded");
                this.initFireBase(config, key);
            })
        }
    }
    constructor(private _api:Api)
    {
        super();
        this.init();
    }
}