export class ApiScriptLoader
{
    private static _instance: ApiScriptLoader = new ApiScriptLoader();

    public getScript(_source:string, _async:boolean = true, _handler:()=>void = null)
    {
        let script:HTMLScriptElement = document.createElement('script');
        let head:HTMLHeadElement  = document.getElementsByTagName('head')[0];

        script.async = _async;

        script.onload = (_event: Event):any =>
        {
            if (_handler) _handler();
        };
        script.onerror = (_event: Event):any =>
        {
            if (_handler) _handler();
        };
        script.src = _source;
        head.appendChild(script)

    }
    public getScripts(_sources:string[], _async:boolean = true, _handler:()=>void = null)
    {
        if ((_sources) && (_sources.length > 0))
        {
            let src:string = _sources.splice(0, 1)[0];
            this.getScript(src, _async, ():void =>
            {
                this.getScripts(_sources, _async, _handler);
            })
        }
        else
        {
            _handler();
        }
    }
    public static getInstance(): ApiScriptLoader
    {
        return ApiScriptLoader._instance;
    };
    constructor()
    {
        if(ApiScriptLoader._instance)
        {
            throw new Error("Error: Instantiation failed: Use SingletonClass.getInstance() instead of new.");
        }
        ApiScriptLoader._instance = this;
    }
}