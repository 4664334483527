import {ApiEventDispatcher} from "../events/ApiEventDispatcher";
import {ApiScriptLoader} from "../loaders/ApiScriptLoader";
import {Api} from "../api/Api";
import {SiteApiConfig} from "../../site/SiteApiConfig";

export class GoogleDfp extends ApiEventDispatcher
{
    //example
    //<div data-dfp-id='/1031013/_Gamezhero_v2.0_ad_unit_game_left' data-dfp-size='[160, 600]'></div>

    private _js:string[] = ["https://www.googletagservices.com/tag/js/gpt.js"];
    private _observer_mutation:any;

    private _ready:boolean = false;
    private _load:boolean = false;

    private DPF_ID:string   = "data-dfp-id";
    private DPF_SIZE:string = "data-dfp-size";


    private load():void
    {
        this._load = true;
        ApiScriptLoader.getInstance().getScripts(this._js, true, ():void =>
        {
            console.log("GoogleDfp loaded");

            this._ready = true;
            this.handlerMutation();
        });
    }

    private updateDfpZones(_list:NodeListOf<HTMLDivElement>):void
    {
        let n:number = _list.length;
        let w:any = window;

        w.googletag.cmd.push(():void =>
        {
            for (let i:number = 0; i < n; i++)
            {
                let item:HTMLDivElement = _list.item(i);

                let id:string      = item.attributes.getNamedItem(this.DPF_ID).value;
                let size:number[]  = JSON.parse(item.attributes.getNamedItem(this.DPF_SIZE).value);
                let div_id:string  = "div-gpt-ad-"+(new Date()).getTime();

                item.removeAttribute(this.DPF_ID);
                item.removeAttribute(this.DPF_SIZE);
                item.setAttribute("id", div_id);

                item.style.display = "block";
                item.style.width    = size[0]+"px";
                item.style.height   = size[1]+"px";

                w.googletag.defineSlot(id, size, div_id).addService(w.googletag.pubads());
                w.googletag.pubads().enableSingleRequest();
                w.googletag.enableServices();
                w.googletag.cmd.push(():void =>
                {
                    w.googletag.display(div_id);
                });
            }
        });
    }
    private handlerMutation = (_mutationsList:MutationRecord = null, _observer:any = null):void =>
    {
        console.log("DFP!");
        let list:NodeListOf<HTMLDivElement> = document.querySelectorAll("div["+this.DPF_ID+"]");
        if (list.length > 0)
        {
            if (this._ready)
            {
                this.updateDfpZones(list);
            }
            else if (this._load == false)
            {
                this.load();
            }
        }
    };
    private init():void
    {
        console.log("Google DPF", this._api.config.get(SiteApiConfig.APP_GOOGLE_DFP_ENABLE));
        let enable:boolean = this._api.config.get(SiteApiConfig.APP_GOOGLE_DFP_ENABLE);
        if (enable)
        {
            let w:any = window;
            w.googletag     = w.googletag     || {};
            w.googletag.cmd = w.googletag.cmd || [];

            let MutationObserver = w.MutationObserver;
            if (MutationObserver)
            {
                let config:MutationObserverInit =
                    {
                        childList:  true,
                        subtree:    true
                    };

                this._observer_mutation = new MutationObserver(this.handlerMutation);
                this._observer_mutation.observe(document.body, config);
            }
            else
            {
                document.body.addEventListener('DOMSubtreeModified',():void =>
                {
                    this.handlerMutation();
                });
            }
        }
    }
    constructor(private _api:Api)
    {
        super();
        this.init();
    }
}