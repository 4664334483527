import {ApiConfig} from "../../../../../../onlinemahjong.games/src/com/boombing/libs/config/ApiConfig";

export class CathTubeSiteApiConfig extends ApiConfig
{
    public static APP_GOOGLE_FIREBASE_CONFIG:string     = "app_google_firebase_config";
    public static APP_GOOGLE_FIREBASE_KEY_PAIR:string   = "app_google_firebase_key_pair";

    constructor()
    {
        super()
    }
}