import {ApiConfig} from "../libs/config/ApiConfig";

export class SiteApiConfig extends ApiConfig
{

    public static APP_FACEBOOK_LOGIN_APP_ID:string      = "app_facebook_login_app_id";
    public static APP_FACEBOOK_AUTO_LOGIN:string        = "app_facebook_auto_login";
    public static APP_FACEBOOK_LOGIN_SCOPES:string      = "app_facebook_login_scopes";


    public static APP_USER_PROFILE:string      = "app_user_profile";


    constructor()
    {
        super()
    }
}